import { useAuthStore } from '~/stores/auth.store';
import {
  type AccountModals,
  AccountModalsStrings,
} from '~/interfaces/dto/general/modals';

interface IMessageHandler {
  action: string;
  actionValue: string;
}

export default function (request: IMessageHandler) {
  const { action, actionValue } = request;
  const router = useRouter();
  const authStore = useAuthStore();
  const siteStore = useSiteStore();
  switch (action.toUpperCase()) {
    case 'NAVIGATETO':
    case 'LAUNCHGAME':
      router.push(actionValue);
      break;
    case 'NAVIGATENEWTAB':
      window.open(actionValue);
      break;
    case 'LAUNCH':
      const detail = actionValue === 'registration' ? 'register' : actionValue;
      const refinedAction = detail.replace(/[^a-zA-Z]/g, '').toLowerCase();
      router.push(detail).then(() => {
        AccountModalsStrings.includes(refinedAction as Partial<AccountModals>)
          ? authStore.setAccountModalPage(
              refinedAction as Partial<AccountModals>,
            )
          : siteStore.activateModal(refinedAction);
      });
      break;
    case 'ACCOUNT':
      authStore.toggleAccountModal(true);
      break;
    // case 'MOREINFO':
    //     window.betwaySynapseInstance.showMoreInfoDialog(actionValue)
    //     break
    default:
      break;
  }
}
